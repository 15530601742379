<script setup lang="ts">
import type { CmsElementText } from "@shopware-pwa/composables-next";
import { useCmsElementConfig } from "#imports";
import { computed } from "vue";
import type { CSSProperties } from "vue";


const props = defineProps<{
  content: CmsElementText;
}>();

const { getConfigValue } = useCmsElementConfig(props.content);

const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

const style = computed<CSSProperties>(() => ({
  alignItems: getConfigValue("verticalAlign"),
}));

const hasVerticalAlignment = computed(() => !!style.value.alignItems);

const buttonActive = computed(() => {
  return getConfigValue('buttonActive');
})

const buttonText = computed(() => {
  return getConfigValue('buttonText');
})

const buttonLink = computed(() => {
  return getConfigValue('buttonLink');
})

const buttonTarget = computed(() => {
  return getConfigValue('buttonTarget');
})

const buttonVariant = computed(() => {
  const style = getConfigValue('buttonStyle')
  if (style == 'text') {
    return 'text';
  }
  return 'flat';
})

const buttonColor = computed(() => {
  const style = getConfigValue('buttonStyle')
  if (style == 'text') {
    return 'undefined';
  }
  return style;
})

const buttonAlign = computed(() => {
  return getConfigValue('buttonAlign');
})

const CmsTextRender = () => {
  const { resolveUrl } = useUrlResolver();

  const config = {
    textTransformer: (text: string) => decodeHTML(text),
    extraComponentsMap: {
      link: {
        conditions(node: NodeObject) {
          return (
              node.type === "tag" &&
              node.name === "a" &&
              !node.attrs?.class?.match(/btn\s?/)
          );
        },
        renderer(node: any, children: any, createElement: any) {
          return createElement(
              "a",
              {
                class:
                    "underline text-base font-normal gray-900 hover:text-secondary-900",
                ...getOptionsFromNode(node, resolveUrl).attrs,
              },
              [...children],
          );
        },
      },
      button: {
        conditions(node: NodeObject) {
          return (
              node.type === "tag" &&
              node.name === "a" &&
              node.attrs?.class?.match(/btn\s?/)
          );
        },
        renderer(node: NodeObject, children: any, createElement: any) {
          let _class = "";
          if (node?.attrs?.class) {
            const btnClass =
                "rounded-md inline-block my-2 py-2 px-4 border border-transparent text-sm font-medium focus:outline-none disabled:opacity-75";

            _class = node.attrs.class
                .replace("btn-secondary", `${btnClass} bg-dark text-white`)
                .replace("btn-primary", `${btnClass} bg-primary text-white`);
          }

          return createElement(
              "a",
              {
                class: _class,
                ...getOptionsFromNode(node, resolveUrl).attrs,
              },
              [...children],
          );
        },
      },
      font: {
        conditions(node: NodeObject) {
          return node.type === "tag" && node.name === "font";
        },
        renderer(node: NodeObject, children: any, createElement: any) {
          // convert from <font color="#ce0000">Headline 1</font> to <span style="color:#ce0000">Headline 1</span>
          let newStyle = null;
          const styleColor = node?.attrs?.color;
          if (styleColor) {
            const currentStyle = node.attrs?.style ?? "";
            newStyle = `color:${styleColor};${currentStyle}`;
            delete node.attrs?.color;
          }

          return createElement(
              "span",
              {
                style: newStyle,
                ...getOptionsFromNode(node, resolveUrl).attrs,
              },
              [...children],
          );
        },
      },
      img: {
        conditions(node: NodeObject) {
          return node.type === "tag" && node.name === "img";
        },
        renderer(node: any, children: any, createElement: any) {
          return createElement(
              "img",
              getOptionsFromNode(node, resolveUrl)?.attrs,
          );
        },
      },
    },
  };
  const rawHtml =
      mappedContent.value?.length > 0
          ? mappedContent.value
          : "<div class='cms-element-text missing-content-element'></div>";
  return renderHtml(rawHtml, config, h, context, resolveUrl);
};
</script>
<template>
  <div
      :class="{ flex: hasVerticalAlignment, 'flex-row': hasVerticalAlignment }"
      :style="style"
  >
    <div>
      <CmsElementText :content="props.content" />

      <div :style="{'textAlign': buttonAlign}">
        <v-btn
            class="mt-8 font-weight-bold"
            v-if="buttonActive"
            :variant="buttonVariant"
            :color="buttonColor"
            :rounded="true"
            :to="formatLink(buttonLink)"
            :target="buttonTarget"
            size="large"
        >
          {{ buttonText }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<style scoped>
:deep(ol), :deep(ul), :deep(dl) {
  list-style-type: disc;
  padding-left: 40px;
  margin-top: 0;
  margin-bottom: 1rem;
}

</style>
